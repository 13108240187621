import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  accWalletIcon: {
    width: '25px',
    height: '25px',
  },
}));

export default useStyles;
