import { Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

import { CTooltip } from '..';
import { truncSymbol } from '../../utils/formating';

interface ITruncate {
  text: string;
  len?: number;
  mobileLen?: number;
  className?: string;
}

const Truncate: React.FC<ITruncate> = ({ text, len = 5, mobileLen, className }) => {
  const checkLength = isMobile && mobileLen ? mobileLen : len;
  return text.length > checkLength ? (
    <CTooltip title={text}>
      <Typography className={className} variant='body2'>
        {truncSymbol(text, checkLength)}
      </Typography>
    </CTooltip>
  ) : (
    <Typography className={className} variant='body2'>
      {text}
    </Typography>
  );
};

export default Truncate;
