import { Box, CircularProgress } from '@material-ui/core';

import useStyles from './styles';

const ContainerLoader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <CircularProgress size={40} />
    </Box>
  );
};

export default ContainerLoader;
