import { Box, Typography } from "@material-ui/core";

import position from "../../assets/svgs/position.svg";
import useStyles from "./styles";

interface INothing {
  children?: React.ReactNode;
  title?: string;
  containerClassName?: string;
}

const Nothing: React.FC<INothing> = ({
  children,
  title = "Nothing to show",
  containerClassName = "",
}) => {
  const classes = useStyles();
  // const default_ = <Typography variant='body2'>{title}</Typography>;
  const default_ = (
    <Box
      style={{
        padding: "20px",
        opacity: 0.3,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src={position} alt="position image" />
      <Box mt="10px" />
      <Typography variant="h6">{title}</Typography>
    </Box>
  );
  return (
    <Box className={[classes.nothingContainer, containerClassName].join(" ")}>
      {children ?? default_}
    </Box>
  );
};

export default Nothing;
