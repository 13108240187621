import Web3 from 'web3';
import { provider } from 'web3-core';
import { UnsupportedChainIdError } from '@web3-react/core';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from '@web3-react/walletconnect-connector';
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from '@web3-react/frame-connector';

export const getLibrary = (provider: provider): Web3 => {
  return new Web3(provider);
};

export const getErrorMessage = (error: any): string => {
  if (error instanceof UnsupportedChainIdError) {
    return 'Unsupported network';
  } else if (error instanceof NoEthereumProviderError) {
    return 'No wallet found';
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect ||
    error instanceof UserRejectedRequestErrorFrame
  ) {
    return 'Wallet connection rejected';
  } else if (error.code === -32002) {
    return 'Wallet connection request pending';
  } else {
    return 'An error occurred';
  }
};
